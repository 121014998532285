<template>
  <div :class="['font-bold w-fit p-[10px] rounded']">
    {{ statusOrderLabel }}
    <span
      v-if="statusOrderLabel === 'Retur'"
      class="font-normal"
    >
      ({{ retur === 1 ? 'Dalam Perjalanan' : 'Diterima' }})
    </span>
  </div>
</template>

<script>
export default ({
  props: {
    statusOrder: {
      type: String,
      required: true,
    },
    retur: {
      type: Number,
      default: 0,
      required: false,
    },
  },

  computed: {
    statusOrderLabel() {
      let label = ''

      switch (this.statusOrder) {
        case 'Diajukan':
          label = 'Order dibuat'
          break

        case 'Batal':
          label = 'Order Dibatalkan'
          break

        case 'Hilang':
          label = 'Paket Hilang'
          break

        case 'Rusak':
          label = 'Paket Rusak'
          break

        default:
          label = this.statusOrder
          break
      }

      return label
    },
  },
})
</script>
