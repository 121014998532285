<template>
  <b-card>
    <b-row class="mb-4 px-1">
      <b-button
        variant="primary"
        class="rounded-lg"
        size="sm"
        @click="$router.back()"
      >
        <b-icon-chevron-left />
      </b-button>
    </b-row>
    <b-row class="justify-content-between">
      <div>
        <h3 class="font-bold mb-3 ml-1">
          Detail Order
        </h3>
      </div>
      <div class="mr-2">
        <b-button
          v-if="orderData.order_status === 'Diajukan'"
          variant="flat-primary"
          size="sm"
          class="my-auto text-primary font-bold mr-50"
          @click="cancelOrder"
        >
          Batal
        </b-button>
        <b-button
          v-if="orderData.order_status === 'Dipacking'"
          variant="flat-primary"
          size="sm"
          class="btn-icon mr-50"
          @click="cancelPickup"
        >
          <b-spinner
            v-if="loadingCancelPickup"
            small
          />
          Batalkan
        </b-button>
      </div>
    </b-row>

    <b-overlay
      :show="loadingDetailOrder"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity="0.3"
    >
      <b-container>
        <h4 class="font-bold mb-1">
          Informasi Order
        </h4>
        <div class="border px-2 pt-2">
          <b-row class="mb-1">
            <b-col cols="6">
              No Order
            </b-col>
            <b-col
              cols="6"
              class="font-bold"
            >
              {{ orderData.order_no }}
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col cols="6">
              Tanggal Order
            </b-col>
            <b-col
              cols="6"
              class="font-bold"
            >
              {{ DAY_MONTH_YEAR(orderData.order_date) }}
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col cols="6">
              Metode Pembayaran
            </b-col>
            <b-col
              cols="6"
              class="font-bold"
            >
              {{ orderData.payment_method }}
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col cols="6">
              Status
            </b-col>
            <b-col
              cols="6"
              class="font-bold"
            >
              <b-alert
                show
                :variant="statusOrder"
                class="max-w-60 d-flex justify-content-center"
              >
                <BadgeStatusOrder
                  :status-order="orderData.order_status"
                  :retur="orderData.is_retur"
                />
              </b-alert>
            </b-col>
          </b-row>
          <span class="d-flex mt-20 mb-1">Telah ditambahkan oleh ‘{{ profile.user_fullname }}’ pada {{ FULL_DATE_TIME(orderData.order_date) }} WIB</span>
        </div>
        <h4 class="font-bold mt-2 mb-1">
          Informasi Pengiriman
        </h4>
        <div class="border px-2 pt-2 pb-1">
          <b-row class="mb-1">
            <b-col cols="6">
              <div class="d-flex">
                <img
                  src="https://storage.googleapis.com/komerce/assets/icons/profile-placehold.svg"
                  alt="Komerce"
                >
                <div class="ml-1 my-auto">
                  <span class="font-bold">{{ orderData.customer_name }}</span><br>
                  <span>{{ orderData.customer_phone }}</span>
                </div>
              </div>
            </b-col>
            <b-col cols="6">
              <span
                v-if="profile.is_komship === 1"
                class="d-flex my-auto justify-end"
              >
                Pengiriman via <img
                  src="https://storage.googleapis.com/komerce/assets/logo/Komship.png"
                  style="margin-left:5px;"
                  alt="Komship"
                >
              </span>
              <span v-else>
                Pengiriman Non Komship
              </span>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col cols="6">
              Ekspedisi
            </b-col>
            <b-col
              cols="6"
              class="font-bold d-flex"
            >
              <img
                :src="orderData.shipment_image_path"
                style="width: 45px"
              ><span
                class="my-auto"
                style="margin-left:5px"
              >{{ orderData.shipment_alias }}</span>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col cols="6">
              No Resi
            </b-col>
            <b-col
              cols="6"
              class="font-bold d-flex"
            >
              {{ orderData.airway_bill }}
              <img
                v-if="orderData.airway_bill"
                src="https://storage.googleapis.com/komerce/assets/icons/copy.png"
                class="copy-resi"
                @click.prevent="copyResi(orderData.airway_bill)"
              >
              <span v-if="orderData.airway_bill === null">-</span>
            </b-col>
          </b-row>
          <b-row class="mb-1">
            <b-col cols="6">
              Detail Alamat
            </b-col>
            <b-col
              cols="6"
              class="font-bold"
            >
              {{ orderData.customer_address }}
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col class="d-flex justify-content-end">
              <button
                class="btn btn-outline-primary"
                @click="$bvModal.show('tracking-resi')"
              >
                Lacak resi
              </button>
              <b-modal
                id="tracking-resi"
                ref="tracking-resi"
                hide-footer
                hide-header
                centered
                no-close-on-backdrop
                no-close-on-esc
                size="lg"
              >
                <BOverlay
                  :show="isLoading"
                  spinner-variant="primary"
                  variant="light"
                  blur="0"
                  opacity=".5"
                  rounded="sm"
                >
                  <div>
                    <HistoryShipping :awb="orderData.airway_bill" />
                  </div>
                </BOverlay>
              </b-modal>
            </b-col>
          </b-row>
        </div>
        <h4 class="font-bold mt-2 mb-1">
          Informasi Penjualan
        </h4>
        <div class="border pb-2">
          <b-table
            responsive
            :fields="fieldOrder"
            :items="itemOrder"
          >
            <template #cell(no)="data">
              {{ data.index + 1 }}
            </template>

            <template #cell(product_name)="data">
              <h5 class="text-black">
                <strong>
                  {{ data.item.product_name }}
                </strong>
              </h5>
              <h4 class="text-primary">
                <strong>
                  {{ data.item.variant_name }}
                </strong>
              </h4>
            </template>
            <template #cell(price)="data">
              {{ IDR(data.item.price) }}
            </template>
            <template #cell(subtotal)="data">
              {{ IDR(data.item.price * data.item.qty) }}
            </template>
          </b-table>
          <hr>
          <b-row class="mt-3">
            <b-col lg="3" />
            <b-col
              lg="5"
            >
              Total Harga Produk
            </b-col>
            <b-col
              lg="3"
              class="text-right"
            >
              {{ IDR(orderData.subtotal) }}
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col lg="3" />
            <b-col
              lg="5"
            >
              Ongkos Kirim
            </b-col>
            <b-col
              lg="3"
              class="text-right"
            >
              {{ IDR(orderData.shipping_cost) }}
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col lg="3" />
            <b-col
              lg="5"
            >
              Potongan Harga
            </b-col>
            <b-col
              lg="3"
              class="text-right"
            >
              - {{ IDR(orderData.discount) }}
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col lg="3" />
            <b-col
              lg="5"
            >
              Biaya Lain
            </b-col>
            <b-col
              lg="3"
              class="text-right"
            >
              {{ IDR(orderData.additional_cost) }}
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col lg="3" />
            <b-col lg="8">
              <hr><span />
            </b-col>
          </b-row>
          <b-row
            class="mt-1"
            :class="orderData.order_status === 'Retur' ? 'line-through' : ''"
          >
            <b-col lg="3" />
            <b-col
              lg="5"
              class="font-bold text-xl"
            >
              Total Pembayaran ({{ orderData.payment_method }}) :
            </b-col>
            <b-col
              lg="3"
              class="text-right font-bold text-primary text-xl"
            >
              {{ IDR(orderData.grandtotal) }}
            </b-col>
          </b-row>
          <b-row
            v-if="orderData.grandtotal !== orderData.old_grandtotal"
            class="mt-1"
          >
            <b-col lg="3" />
            <b-col
              lg="5"
              class="font-bold text-sm text-primary"
            >
              {{ orderData.notes }}
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col lg="3" />
            <b-col lg="7">
              <hr><span />
            </b-col>
            <b-col
              lg="2"
              class="d-flex justify-start"
            >
              <b-button
                v-b-toggle="'collapse-1'"
                class="buttonCollapse px-0"
                variant="none"
                size="sm"
              >
                <span class="when-open">Tutup <b-icon-chevron-up /></span>
                <span class="when-closed">Buka <b-icon-chevron-down /></span>
              </b-button>
            </b-col>
          </b-row>
          <b-collapse id="collapse-1">
            <b-row class="mt-1">
              <b-col lg="3" />
              <b-col
                lg="5"
              >
                Biaya {{ orderData.payment_method }} ({{ orderData.service_fee_to }}% sudah termasuk PPN)
              </b-col>
              <b-col
                lg="3"
                class="text-right"
              >
                <span>- {{ IDR(orderData.service_fee) }}</span>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col lg="3" />
              <b-col
                lg="5"
              >
                Ongkos Kirim (dipotong Cashback {{ orderData.cashback_to }}%)
              </b-col>
              <b-col
                lg="3"
                class="text-right"
              >
                - {{ IDR(orderData.shipping_cost - orderData.shipping_cashback) }}
              </b-col>
            </b-row>
            <b-row
              v-if="orderData.order_status === 'Retur'"
              class="mt-1"
            >
              <b-col lg="3" />
              <b-col
                lg="5"
              >
                Ongkos Kirim Pengembalian (diskon {{ orderData.percentage_cost_retur }}%)
              </b-col>
              <b-col
                lg="3"
                class="text-right"
              >
                - {{ IDR(orderData.shipping_retur) }}
              </b-col>
            </b-row>
            <b-row
              v-if="orderData.is_insurance === 1"
              class="mt-1"
            >
              <b-col lg="3" />
              <b-col lg="5">
                <div class="d-flex">
                  <span class="mr-1">Biaya Asuransi</span>
                  <img
                    id="popover-asuransi"
                    src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
                    alt=""
                  >
                  <b-popover
                    triggers="hover focus"
                    target="popover-asuransi"
                    placement="topright"
                  >
                    <a
                      href="https://bantuan.komerce.id/id/article/asuransi-pengiriman-ekspedisi-di-komship-17pow0b/?bust=1678871763794"
                      target="_blank"
                      class="text-[#08A0F7]"
                    ><u>Klik disini</u></a> buat detail tarifnya.
                  </b-popover>
                </div>
              </b-col>
              <b-col
                lg="3"
                class="text-right text-[#F95031]"
              >
                <span>- {{ IDR(orderData.insurance_fee) }}</span>
              </b-col>
            </b-row>
          </b-collapse>
          <b-row class="mt-1">
            <b-col lg="3" />
            <b-col
              lg="5"
              class="font-bold"
            >
              Penghasilan bersih yang kamu dapatkan
            </b-col>
            <b-col
              v-if="statusNetProfit === '-'"
              lg="3"
              class="text-right text-primary font-bold"
            >
              {{ IDR(orderData.net_profit) }}
            </b-col>
            <b-col
              v-else
              lg="3"
              class="text-right text-success font-bold"
            >
              {{ IDR(orderData.net_profit) }}
            </b-col>
          </b-row>
        </div>
      </b-container>
    </b-overlay>

    <!-- Popup success cancel pickup -->
    <b-modal
      ref="popup-success-cancel-pickup"
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
      centered
    >
      <b-row class="justify-content-center mb-2 pt-2">
        <b-img src="https://storage.googleapis.com/komerce/core/icon-popup-success.png" />
      </b-row>

      <b-row class="justify-content-center">
        <h4 class="text-black text-center">
          <strong>
            Order Berhasil Dibatalkan
          </strong>
        </h4>
      </b-row>

      <b-row class="justify-content-center mb-1">
        <span class="text-black text-center">
          Order dan pengajuan pickup berhasil dibatalkan.
        </span>
      </b-row>

      <b-row class="justify-content-center pb-1">
        <b-button
          variant="primary"
          class="btn-icon"
          @click="fetchData"
        >
          Oke
        </b-button>
      </b-row>
    </b-modal>

  </b-card>
</template>
<script>

import {
  VBModal,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { FULL_DATE_TIME, DAY_MONTH_YEAR } from '@/libs/filterDate'
import { IDR } from '@/libs/currency'
import HistoryShipping from './HistoryShipping.vue'
import BadgeStatusOrder from '../komship/search-order/BadgeStatusOrder.vue'

export default {
  components: {
    HistoryShipping,
    BadgeStatusOrder,
  },
  directives: { VBModal },
  data() {
    return {
      FULL_DATE_TIME,
      DAY_MONTH_YEAR,
      IDR,
      profile: {},
      orderData: [],
      statusOrder: null,
      fieldOrder: [
        { key: 'no', label: 'No' },
        { key: 'product_name', label: 'Nama Produk' },
        { key: 'price', label: 'Harga Satuan' },
        { key: 'qty', label: 'Jumlah' },
        { key: 'subtotal', label: 'Sub Total' },
      ],
      itemOrder: [],
      isLoading: false,
      statusNetProfit: null,
      idEditOrder: this.$route.params.order_id,

      loadingDetailOrder: false,
      loadingCancelPickup: false,
    }
  },
  mounted() {
    this.idEditOrder = this.$route.params.order_id
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.loadingDetailOrder = true
      this.$refs['popup-success-cancel-pickup'].hide()
      const order = await this.$http_komship.get(`/v1/ticket-admin/order/detail/${this.$route.params.id}`)
      const { data } = await order.data
      this.orderData = await data
      this.statusNetProfit = data.net_profit.toString().charAt(0)
      this.itemOrder = await data.product
      this.statusOrder = await this.setAlert(data.order_status)
      this.loadingDetailOrder = false
    },
    setAlert(status) {
      if (status === 'Diajukan') {
        this.statusOrder = 'primary'
      } else if (status === 'Dipacking') {
        this.statusOrder = 'info'
      } else if (status === 'Dikirim') {
        this.statusOrder = 'warning'
      } else if (status === 'Diterima') {
        this.statusOrder = 'success'
      } else if (status === 'Retur' || status === 'Hilang' || status === 'Rusak') {
        this.statusOrder = 'danger'
      } else if (status === 'Batal') {
        this.statusOrder = 'dark'
      }
      return this.statusOrder
    },
    cancelOrder() {
      // eslint-disable-next-line global-require
      const logoWarning = require('@/assets/images/icons/popup-warning.png')
      this.$swal.fire({
        title: 'Kamu yakin ingin<br>menghapus Order?',
        imageUrl: logoWarning,
        showCancelButton: true,
        showLoaderOnConfirm: true,
        confirmButtonText: 'Hapus',
        cancelButtonText: 'Batal',
        cancelButtonColor: '#FFFFFF',
        confirmButtonClass: 'btn btn-primary',
        cancelButtonClass: 'btn btn-outline-primary text-primary',
      }).then(isConfirm => {
        if (isConfirm.value === true) {
          this.$http_komship.delete(`v1/order/${this.profile.partner_id}/delete/${this.$route.params.order_id}`)
            .then(() => {
              this.$router.push('/data-order')
            })
        }
      })
    },
    async copyResi(resi) {
      try {
        await navigator.clipboard.writeText(resi)
        const Toast = this.$swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          didOpen: toast => {
            toast.addEventListener('mouseenter', this.$swal.stopTimer)
            toast.addEventListener('mouseleave', this.$swal.resumeTimer)
          },
        })

        Toast.fire({
          icon: 'success',
          title: '<span class="text-success">Success copy to clipboard</span>',
          showCloseButton: true,
        })
      // eslint-disable-next-line no-empty
      } catch ($e) {
      }
    },
    cancelPickup() {
      this.loadingCancelPickup = true
      this.$http_komship.put(`/v2/order/${this.profile.partner_id}/cancel/${this.idEditOrder}`)
        .then(() => {
          this.loadingCancelPickup = false
          this.$refs['popup-success-cancel-pickup'].show()
        })
        .catch(err => {
          this.loadingCancelPickup = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          }, 2000)
        })
    },
  },
}
</script>
<style scoped lang="scss">
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
